import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'

import * as echarts from 'echarts';
import { plugin } from 'echarts-for-vue';
Vue.use(plugin, { echarts });

import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  render: h => h(App),
}).$mount('#app')

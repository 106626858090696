<template>
<div>
    <h2 class="title">事件抽取</h2>
    <div class="timeline-box" v-loading="loading">
        <div v-if="timelines == null">
            <div style="height: 200px;"></div>
        </div>
        <div v-else>
            <div v-for="(obj, index) in taggedTimeline" :key="index" class="timeline-row">
                <div class="timeline-left">
                    <div>{{ obj.date }}</div>
                    <div>{{ obj.time }}</div>
                </div>
                <div class="timeline-right">
                    <span v-for="(part, index) in obj.content" :key="index" :class="[ 'timeline-' + part.type ]">
                        {{ part.data }}
                    </span>
                </div>
            </div>
            <div class="timeline-hint">
                <span class="hint-participant">人物</span>
                <span class="hint-place">地点</span>
                <span class="hint-event">事件</span>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: ["timelines", "loading"],
    methods: {

        tagContent(data, trigger, type) {
            let st = 0;
            let ret = [];
            while (trigger.length > 0) {
                let p = data.indexOf(trigger, st)
                if (p == -1) {
                    if (st < data.length) {
                        ret.push({
                            "type": "raw",
                            "data": data.slice(st)
                        });
                    }
                    break
                }
                if (st < p) {
                    ret.push({
                        "type": "raw",
                        "data": data.slice(st, p)
                    })
                }
                ret.push({
                    "type": type,
                    "data": data.slice(p, p + trigger.length)
                });
                st = p + trigger.length;
            }
            return ret;
        },
        parseTimeline(obj) {
            let date = "";
            if (obj.time.year != "None") date += obj.time.year + "年";
            if (obj.time.month != "None") date += obj.time.month + "月";
            if (obj.time.date != "None") date += obj.time.date + "日";
            let time = "";
            if (/^\d+$/.test(obj.time.hour)) {
                if (obj.time.minutes != "None") {
                    time += obj.time.hour + ":" + obj.time.minutes;
                }
                else {
                    time += obj.time.hour + "时";
                }
            }
            else {
                if (obj.time.hour != "None") time += obj.time.hour;
            }
            let content = [{"type": "raw", "data":obj.sentence}];
            
            for (let i = 0; i < obj.events.length; ++ i) {
                let nwcontent = [];
                for (let j = 0; j < content.length; ++ j) {
                    if (content[j].type == "raw") {
                        nwcontent = [...nwcontent, ...this.tagContent(content[j].data, obj.events[i].description, "event")]
                    } else {
                        nwcontent.push(content[j]);
                    }
                }
                content = nwcontent;
            }

            for (let i = 0; i < obj.participants.length; ++ i) {
                let nwcontent = [];
                for (let j = 0; j < content.length; ++ j) {
                    if (content[j].type == "raw") {
                        nwcontent = [...nwcontent, ...this.tagContent(content[j].data, obj.participants[i], "participant")]
                    } else {
                        nwcontent.push(content[j]);
                    }
                }
                content = nwcontent;
            }

            for (let i = 0; i < obj.place.length; ++ i) {
                let nwcontent = [];
                for (let j = 0; j < content.length; ++ j) {
                    if (content[j].type == "raw") {
                        nwcontent = [...nwcontent, ...this.tagContent(content[j].data, obj.place[i], "place")]
                    } else {
                        nwcontent.push(content[j]);
                    }
                }
                content = nwcontent;
            }
            return {
                date: date,
                time: time,
                content: content,
                sentence: obj.sentence
            }
        }
    },
    computed: {
        taggedTimeline() {
            if (this.timelines == null) return null;
            return this.timelines.map(this.parseTimeline)
        }
    }
}
</script>
<style scoped>
.timeline-row {
    width: 1000px;
    margin: auto;
    position: relative;
}
.timeline-row::before {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    border-radius: 2px;
    left: 105px;
    top: -4px;
    bottom: -4px;
    background-color: #e5e5e5;
}

.timeline-box {
    width: 1100px;
    box-sizing: border-box;
    min-width: 240px;
    margin: auto; 
    border: 1px solid #eaecf0;
    padding: 48px 24px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
}

.timeline-left {
    display: inline-block;
    width: 100px;
    font-size: 13px;
    text-align: center;
    padding: 8px 0;
    color: rgb(98, 104, 111);
    position: relative;
    vertical-align: top;
}
.timeline-left::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #e5e5e5;
    border: 2px solid rgb(255, 255, 255);
    width: 8px;
    height: 8px;
    border-radius: 8px;
    right: -12px;
    z-index: 100;
    top: 12px;
}
.timeline-right {
    display: inline-block;
    width: 850px;
    margin-left: 50px;
    vertical-align: top;
    padding-top: 2px;
    padding-bottom: 24px;
    line-height: 2em;
}

.timeline-hint span.hint-participant::before {
    background-color: rgba(79, 96, 221, .8);
}
.timeline-participant {
    color: rgb(79, 96, 221);
}
.timeline-hint span.hint-place::before {
    background-color: rgba(157, 80, 24, .8);
}
.timeline-place {
    color: rgb(157, 80, 24);
}
.timeline-hint span.hint-event::before {
    background-color: rgba(17, 129, 147, .8);
}
.timeline-event {
    color: rgb(17, 129, 147);
}
.timeline-hint {
    margin-top: 24px;
    text-align: right;
    padding: 0 32px;
}
.timeline-hint span{
    margin-right: 48px;
    position: relative;
}
.timeline-hint span::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: gray;
    display: inline-block;
    position: absolute;
    left: -18px;
    top: 4px;
    border-radius: 12px;
}
</style>
<template>
<div>
    <h2 class="title">相关法条</h2>
    <div class="article-box" v-loading="loading">
        <div v-if="law == null" style="height: 300px; width: 100%;"></div>
        <div v-else>
            <div class="law-item" v-for="obj in relatedLaw" :key="obj.title">
                <div class="law-title">
                    {{ obj.title }}
                    <div class="prob-bar">
                        <div class="prob-bar-inner" :style="{'width': (obj.prob * 100).toString() + '%'}"></div>
                    </div>
                    <div class="prob-value">
                        {{ (obj.prob * 100).toFixed(2) + '%' }}
                    </div>
                </div>
                <div class="law-content">
                    {{ obj.content }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: ["law", "loading"],
    computed: {
        relatedLaw() {
            if (this.law == null) return null;
            let len = Math.min(5, this.law.length);
            return this.law.slice(0, len).map(x => {
                let tmp = x.content.split("\n");
                return {
                    title: x.name + " " + tmp[0],
                    content: tmp.slice(1).join("\n"),
                    prob: parseFloat(x.prob)
                }
            });
        }
    }
}
</script>

<style scoped>
.article-box {
    width: 1100px;
    box-sizing: border-box;
    min-width: 240px;
    margin: auto; 
    padding: 20px 0;
    border: 1px solid #eaecf0;
    padding: 16px 24px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
}

.law-item {
    padding: 16px 12px;
    margin-bottom: 16px;
}

.law-item:not(:last-child) {
    border-bottom: 1px dashed #cbcccc;
}

.law-title {
    font-weight: bold;
    position: relative;
    line-height: 2em;
    margin-bottom: 0px;
}
.law-content {
    padding: 16px 24px;
}

.prob-bar {
    position: absolute;
    width: 350px;
    height: 1em;
    line-height: 1em;
    top: calc(0.5em - 2px);
    right: 60px;
}

.prob-bar-inner {
    background-color: #45c645;
    height: 4px;
    top: 50%;
    right: 0;
    position: absolute;
    border-radius: 2px;
    transition: width .3s ease-in-out;
}

.prob-value {
    position: absolute;
    height: 1em;
    top: 0.5em;
    right: 0;
    line-height: 1em;
    font-weight: normal;
}
</style>
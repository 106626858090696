<template>
<div>
    <h2 class="title">判决预测</h2>
    <div class="analyze-box" v-loading="loading">

        <div class="charge" style="display: inline-block;">
            <div v-if="charge == null" style="width: 550px; height: 300px;"></div>
            <ECharts v-else style="height: 300px; width: 550px;" :option="chargeOptions"/>
        </div>
        <div class="time" style="display: inline-block;">
            <div v-if="time == null" style="width: 450px; height: 300px;"></div>
            <ECharts v-else style="height: 300px; width: 450px;" :option="timeOptions"/>
        </div>
    </div>
</div>
</template>
<script>


export default {
    props: ["charge", "time", "loading"],
    data: () => ({
    }),
    computed: {
        chargeOptions() {
            if (this.charge == null) return null;
            let data = [];
            for (let i = 0; i < this.charge.length; ++ i) {
                data.push({
                    name: this.charge[i].tag,
                    value: parseFloat(this.charge[i].prob)
                });
            }

            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {d}%'
                },
                series: [
                    {
                        name: '罪名',
                        type: 'pie',
                        radius: '80%',
                        data: data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                            normal: {
                                show: true,
                                textStyle: {
                                    fontSize: 14,
                                }
                            },
                            emphasis: {
                                show: true
                            }
                        }
                    }
                ]
            };
        },
        timeOptions() {
            if (this.time == null) return null;
            return {
                color: ["#cb4545"],
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}%'
                },
                yAxis: {
                    type: 'category',
                    data: this.time.map(x => x.tag)
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} %',
                    }
                },
                series: [{
                    name: '刑期',
                    data: this.time.map(x => (x.prob * 100).toFixed(2) ),
                    type: 'bar',
                    barWidth: 16,
                }],
                grid: {
                    containLabel: true,
                    top: "12%",
                    bottom: "5%",
                },
            };
        }
    }
}
</script>
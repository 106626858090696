<template>
  <div id="app">
    <h2 class="title">事实描述</h2>
    <p style="text-align: right; margin: 0;"><span class="change-case" @click="change_case">换个例子</span></p>
    <el-input
      type="textarea"
      style="font-size: 18px;"
      resize="none"
      placeholder="请输入内容"
      :autosize="{'minRows': 6}"
      v-model="fact">
    </el-input>
    <div style="text-align: center; margin: 36px auto;">
      <el-button style="margin: auto;" type="primary" @click="query">智能分析</el-button>
    </div>

    <!-- <div ref="pile" v-if="!first_query" class="spacer"></div> -->
    <!-- <semantic v-if="!first_query" :semantic="semantic" :loading="semantic_loading" /> -->

    <div ref="pile" v-if="!first_query" class="spacer"></div>
    <timeline v-if="!first_query" :timelines="timelines" :loading="timeline_loading" />

    <div v-if="!first_query" class="spacer"></div>
    <analyze v-if="!first_query" :time="time" :charge="charge" :loading="analyze_loading" />

    <div v-if="!first_query" class="spacer"></div>
    <attributes v-if="!first_query" :attributes="attr" :loading="attributes_loading" />

    <div v-if="!first_query" class="spacer"></div>
    <relatead-law v-if="!first_query" :law="law" :loading="relatedlaw_loading" />
    
    <div class="footer">
      THUNLP &copy; {{ year }}
    </div>
  </div>
</template>

<script>
// import Semantic from "@/components/semantic.vue";
import Analyze from "@/components/analyze.vue";
import Attributes from '@/components/attributes.vue';
import RelateadLaw from '@/components/relateadlaw.vue';
import Timeline from '@/components/timeline.vue';


export default {
  data: ()=>({
    fact: "",
    // semantic: null,   //
    charge: null,     //
    law: null,        //
    time: null,       //
    attr: null,       //
    timelines: null,  //
    

    first_query: true,

    // semantic_loading: true,   //
    timeline_loading: true,   //
    analyze_loading: true,    //
    attributes_loading: true, //
    relatedlaw_loading: true, //

    current_case: 0,
    sample_cases: [
      "2013年10月26日11时许，被告人吴某到咸宁市咸安区官埠桥镇小泉村七组找朱某甲讨要债务时，与朱某甲的儿子朱某乙发生口角以致扭打。当日12时许，吴某邀约一名男子（身份待查）各持一把菜刀赶到咸安区桂香大道小乡泉农庄，吴某持刀将在该农庄吃饭的被害人朱某乙左手小臂砍伤，致朱某乙左前臂血管、神经、肌腱损伤，左桡骨骨折。经咸宁市中心医院法医司法鉴定所鉴定，朱某乙的伤情为轻伤。\n2015年12月17日，被告人吴某与被害人朱某乙达成赔偿协议，吴某按照协议的约定赔偿了朱某乙经济损失共计6.40万元，并取得了朱某乙的谅解。\n上述事实，被告人吴某在开庭审理的过程中亦无异议，并有户籍证明、抓获经过、协议书、谅解书、收条、证人朱某甲、陈某、郑某、窦某的证言、被害人朱某乙的陈述、鉴定意见、辨认笔录等证据予以证实，足以认定。",
			"2014年6月11日10时许，被告人李某驾车行至本市侨乡经济开发区友谊村3组“昌盛物流”公司门前时，因车辆堵塞之事与郭某发生纠纷。当日16时许，被告人李某驾车再次来到该处，与郭某发 生争执，在争执过程中将郭某打伤。经天门市公安司法鉴定中心鉴定，郭某多处软组织损伤，右侧鼻骨粉碎性骨折，损伤程度为轻伤二级。\n2015年2月16日，经天门市公安局侯口派出所主持调解，被告人李某近亲属与被害人郭某自愿达成调解协议，并按协议赔偿了被害人郭某各项经济损失共计37000元。被害人郭某对被告人李某表示谅解。",
			"2016年7月14日中午，被告人孔某在广饶县锦湖小区十号楼二单元101室因琐事与刘某发生争执并打斗。其间被告人孔某用折叠刀将被害人刘某腹部捅伤致其胃脏破裂。经鉴定，被害人刘某的损 伤程度为重伤二级。\n另查明，2016年11月3日，被告人孔某到广饶县公安局广饶街道派出所投案自首。2016年11月8日，被告人孔某赔偿了被害人刘某各种费用共计人民币60000元并已实际履行，被害人刘某对被告人孔某的行为表示谅解。\n上述事实，被告人孔某在开庭审理过程中亦无异议，并有物证—折叠刀一把，被害人刘某陈述，证人王某证言，广饶县公安局受案登记表、扣押清单、法医学人体损伤程度鉴定书、自首经过、发破案经过，赔偿协议书、收条、谅解书及被告人孔某的户籍证明等证据证实，足以认定。",
			"2002年4月1日，被告人温海伙同王磊、马忠在青海省西宁市锦玉招待所预谋购买手枪抢劫作案，并由被告人温海托被害人钱某甲购买枪支，同年4月8日，因钱某甲没有弄到枪，被告人温海伙同王磊、马忠强行将钱某甲带至西宁市锦玉招待所，对钱某甲进行殴打并看管至4月10日18时许，因怕事情败露，被告人温海授意王磊、马忠将钱某甲杀害，二人将被害人钱某甲带至西宁市凤凰山公墓山顶杀害。经鉴定，钱某甲系被他人用领带勒颈并用锐器刺伤颈部，血液流入气管致窒息死亡。\n\n2002年4月中旬，被告人温海伙同王磊、马忠窜至敦煌市租住在七里镇铁家堡小康村8号楼5单元二楼出租房内预谋抢劫作案。4月29日13时许，王磊将被害人王某某骗至出租房内，被告人温海伙同王磊、马忠用尼龙绳、塑料绳勒住王某某的颈部致其死亡，王磊从王某某身上搜得人民币30元及1部三星手机后外出销赃，期间被告人温海对王某某的尸体进行了奸淫，后三人将王某某的尸体藏匿于出租房内的床柜中逃离。经鉴定，王某某系机械性窒息死亡，被抢手机价值1080元。\n\n原判决认定上述事实的证据有：物证、书证、证人证言、勘验、辨认笔录、鉴定意见、被告人供述、视听资料等。"
    ],

    year: "2021",
  }),
  mounted() {
    this.current_case = parseInt(Math.random() * this.sample_cases.length);
    this.change_case();

    this.year = (new Date()).getFullYear().toString();
  },
  methods: {
    change_case() {
      this.current_case ++;
      if (this.current_case >= this.sample_cases.length) this.current_case = 0;
      this.fact = this.sample_cases[this.current_case];
    },
    error(err) {
      this.$message.error(err);
    },
    query() {
      this.first_query = false;
      
      this.timeline_loading = true;
      this.axios.post("/api/event_extraction", {
        query: this.fact
      }).then((res) => {
        if (res.data.code == 0) {
          this.timelines = res.data.data.timelines;
        } else {
          this.error("获取时间线失败：code " + res.data.code.toString());
        }
        this.timeline_loading = false;
      }).catch((err) => {
        this.error("获取时间线失败：" + err.toString());
      });

      
      this.analyze_loading = true;
      this.attributes_loading = true;
      this.relatedlaw_loading = true;
      this.axios.post("/api/judgment_prediction", {
        query: this.fact
      }).then((res) => {
        if (res.data.code == 0) {
          this.time = res.data.data.time;
          this.attr = res.data.data.attr;
          this.charge = res.data.data.charge;
          this.law = res.data.data.law;
        } else {
          this.error("案情分析错误：code " + res.data.code.toString());
        }
        this.analyze_loading = false;
        this.attributes_loading = false;
        this.relatedlaw_loading = false;
      }).catch((err) => {
        this.error("案情分析错误：" + err.toString());
      });

      
      // this.semantic_loading = true;

      this.$nextTick().then(() => {
        window.scrollTo({ 
          top: this.$refs.pile.offsetTop,
          left: 0, 
          behavior: 'smooth' 
        }); 
      });
    }
  },
  components: {
    // Semantic,
    Analyze,
    Attributes,
    RelateadLaw,
    Timeline,
  }
}
</script>
<style scoped>
.change-case {
  font-size: 14px;
  font-weight: normal;
  color: #2385fe;
  cursor: pointer;
  line-height: 1em;
  margin-top: -1em;
}
.spacer {
  height: 90px;
  width: 100%;
}
.footer {
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
</style>
<style>
html, body {
  background: #f8fafb;
  height: 100%;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  padding: 80px 0;
  width: 1100px;
  margin: auto;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
}

.title {
  position: relative;
  margin-bottom: 36px;
}
.title::after {
  content: '';
  position: absolute;
  width: 80px;
  bottom: -8px;
  height: 4px;
  background-color: rgb(92, 157, 221);
  left: 2px;
  border-radius: 2px;
}

</style>

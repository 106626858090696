<template>
<div>
    <h2 class="title">判决要素</h2>
    <div class="attribute-box" v-loading="loading">
        <div v-if="attributes == null" style="height: 400px; width: 850px; margin: auto;"></div>
        <ECharts v-else style="height: 400px; width: 850px; margin: auto;" :option="attrOptions"/>
    </div>
</div>
</template>
<script>
export default {
    props: ["attributes", "loading"],
    computed: {
        attrOptions() {
            if (this.attributes == null) return null;
            return {
                color: ["#6fa8dc"],
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}%'
                },
                xAxis: {
                    type: 'category',
                    data: this.attributes.map(x => x.tag),
                    axisLabel: {
                        rotate: 45,
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} %',
                    }
                },
                series: [{
                    name: '属性',
                    data: this.attributes.map(x => (x.prob * 100).toFixed(2) ),
                    type: 'bar',
                    barWidth: 16,
                }],
                grid: {
                    containLabel: true,
                    top: "2%",
                    bottom: "2%",
                    left: "5%",
                    right: "5%",
                },
            };
        }
    }
}
</script>